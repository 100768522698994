import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import media from "styled-media-query";

import { AnimatePresence, motion } from "framer-motion";
import { Title, Bullets } from "../../../components";

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`;

const ImagesBlock = styled.div`
  width: 60%;
  height: 850px;
  background: white;
  position: relative;
  overflow: hidden;
  margin-top: auto;

  .gatsby-image-wrapper {
    min-height: 100%;
  }

  :after {
    content: "";
    width: 100%;
    height: 100%;
    background: #0d1f1f;
    opacity: 0.2;
    position: absolute;
    top: 0;
    right: -33.3%;
    z-index: -1;

    ${media.lessThan("medium")`
      right: 0;
      left: 0;
      margin: auto;
    `}
  }

  ${media.lessThan("medium")`
    width: 100%;
    height: auto;
  `}
`;

const BulletsWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  left: 60px;
  z-index: 3;
`;

const ContentBlock = styled.div`
  width: 40%;
  padding: 5vw;
  padding-left: 0;
  position: relative;
  margin-top: auto;
  background: white;

  :after {
    content: "";
    width: 120px;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 100%;
    background: white;
  }

  :before {
    content: "";
    width: 2px;
    height: 10%;
    position: absolute;
    top: 0;
    right: calc(100% - 2px);
    background: black;
    z-index: 2;

    ${media.lessThan("medium")`
      right: auto;
      left: 2%;
      height: 20px;
    `}
  }

  ${media.lessThan("medium")`
    width: 90%;
    margin: 0 auto;

    ${Title} {
      font-size: 36px !important;
    }
  `}

  & > div:last-of-type {
    margin: 50px 0;
    margin-bottom: 80px;
    justify-content: center;
  }
`;

const Texts = styled(motion.div)`
  margin-bottom: 45px;
  p {
    color: #6c6c6c !important;
  }
`;

const InnerBlock = styled.div`
  margin-bottom: 3vw;
`;

const Rooms = ({ slides }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const currentSlide = useMemo(() => {
    return slides[currentSlideIndex];
  }, [currentSlideIndex, slides]);

  const currentImage = useMemo(() => {
    return currentSlide.images[currentImageIndex];
  }, [currentSlide, currentImageIndex]);

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [currentSlideIndex]);

  return (
    <Section>
      <ImagesBlock>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={`Image_${currentSlideIndex}_${currentImageIndex}`}
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1.05 }}
            exit={{ opacity: 0, scale: 1.1 }}
            transition={{ type: "spring", damping: 60, stiffness: 300 }}
            style={{ height: "100%" }}
          >
            <GatsbyImage
              image={getImage(currentImage.gatsbyImageData)}
              alt={currentSlide.title}
              loading="eager"
            />
          </motion.div>
        </AnimatePresence>

        {currentSlide.images.length > 1 && (
          <BulletsWrapper>
            <Bullets
              total={currentSlide.images.length}
              active={currentImageIndex}
              onChangeCurrent={(current) => setCurrentImageIndex(current)}
            />
          </BulletsWrapper>
        )}
      </ImagesBlock>

      <ContentBlock>
        <AnimatePresence exitBeforeEnter>
          <InnerBlock
            as={motion.div}
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            key={`InnerContent_${currentSlideIndex}`}
            transition={{ type: "spring", damping: 60, stiffness: 300 }}
          >
            <Title variant="h2" as={motion.h3} color="#27494A" style={{ marginBottom: 45 }}>
              {currentSlide.title}
            </Title>
            <Texts dangerouslySetInnerHTML={{ __html: currentSlide.text }} />
          </InnerBlock>
        </AnimatePresence>

        <Bullets
          total={slides.length}
          active={currentSlideIndex}
          onChangeCurrent={(current) => setCurrentSlideIndex(current)}
        />
      </ContentBlock>
    </Section>
  );
};

export default Rooms;
