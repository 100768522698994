import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import media from "styled-media-query";
import { Page, Title, Carousel, ScrollIndicator } from "../components";
import HalfSlider from "../components/Home/Rooms";

const Hero = styled.div`
  width: 100%;
  margin: auto;
  padding: 0 2%;
  background: white;
  overflow: hidden;
  position: relative;

  ${media.lessThan("medium")`
    .gatsby-image-wrapper {
      min-height: 50vh;
      margin-top: 2vw;
    }
  `}
`;

const History = styled.div`
  padding: 220px 10%;
  position: relative;

  :after {
    content: "";
    width: clamp(18%, 15vw, 50%);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    z-index: 0;
  }

  ${media.lessThan("medium")`
    padding: 60px 0;
    width: 100%;

    :after {
      width: 100%;
      height: 110%;
    }

    h2 {
      font-size: 36px !important;
    }
  `}
`;

const Container = styled.div`
  width: 90%;
  max-width: 1010px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
`;

const Texts = styled.div`
  margin-left: auto;
  max-width: clamp(800px, 80vw, 450px);

  p {
    font-size: 18px;
    line-height: 1.5em;
    font-family: "Raleway";
    margin-top: 30px;
    color: #6c6c6c;
  }
`;
const Rooms = styled.div`
  width: 100%;
`;

const Team = styled.div`
  ${media.lessThan("medium")`
    ${Title} {
      font-size: 50px;
      margin-top: 60px !important;
      margin-bottom: 30px !important;
    }
  `}
`;

const PageTitle = styled(Title)`
  position: absolute;
  bottom: 100px;
  left: 5vw;

  ${media.lessThan("medium")`
    font-size: 50px;
    bottom: 30px;
  `}
`;

const About = ({ data }) => {
  const { capa } = data.contentfulAOriggem;
  const { allContentfulNossaEquipe, allContentfulEstrutura } = data;

  const teamSlides = allContentfulNossaEquipe.edges.map(({ node }) => ({
    id: node.id,
    title: node.titulo,
    text: node.descricao.childrenMarkdownRemark[0].html,
    images: [node.imagem],
  }));

  const structure = allContentfulEstrutura.edges.map(({ node }) => ({
    id: node.id,
    title: node.titulo,
    image: node.capa,
    link: `/nossa-estrutura/${node.slug}`,
  }));

  return (
    <Page title="A Origgem">
      <Hero>
        <GatsbyImage
          image={getImage(capa.gatsbyImageData)}
          alt={capa.description}
          title={capa.title}
        />
        <PageTitle color="white">{capa.title}</PageTitle>

        <ScrollIndicator color="#27494A" />
      </Hero>

      <History>
        <Container>
          <Title as="h2" color="#27494A" variant="h2">
            {data.contentfulAOriggem.titulo}
          </Title>
          <Texts
            dangerouslySetInnerHTML={{
              __html: data.contentfulAOriggem.texto.childrenMarkdownRemark[0].html,
            }}
          />
        </Container>
      </History>

      <Rooms>
        <Carousel items={structure} />
      </Rooms>

      <Team>
        <Title
          color="#27494A"
          variant="h2"
          as="h2"
          style={{ textAlign: "center", width: "100%", marginBottom: 100, marginTop: 130 }}
        >
          Nossa equipe
        </Title>
        <HalfSlider slides={teamSlides} />
      </Team>
    </Page>
  );
};

export const pageQuery = graphql`
  query {
    contentfulAOriggem {
      id
      titulo
      texto {
        id
        childrenMarkdownRemark {
          html
        }
      }
      capa {
        id
        title
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    allContentfulNossaEquipe {
      edges {
        node {
          id
          titulo
          descricao {
            id
            childrenMarkdownRemark {
              html
            }
          }
          imagem {
            gatsbyImageData(layout: CONSTRAINED, width: 1280)
            title
            description
          }
        }
      }
    }

    allContentfulEstrutura {
      edges {
        node {
          id
          slug
          capa {
            gatsbyImageData(layout: FULL_WIDTH)
            title
            description
          }
          titulo
        }
      }
    }
  }
`;

export default About;
